<template>
  <div>
    <RikazExpandableSidebar
      v-model="isOpen"
      class="py-2 mx-auto"
      sidebar="bg-bg_alt_color rounded p-2 shadow"
      :open-width="homeProfileIsActive ? 'w-full' : 'w-48'"
      closed-width="w-9"
    >
      <template #sidebar>
        <button
          v-if="!homeProfileIsActive"
          class="w-5 h-5 mb-2 ltr:mr-auto rtl:ml-auto"
          @click="isOpen = !isOpen"
        >
          <v-icon icon="mdi mdi-menu" :size="20" />
        </button>

        <MevcutLink
          v-for="(link, index) in links"
          :key="`profile-link-${index}`"
          :with-branch-id-query="false"
          :to="link.route"
          :aria-label="link.title"
        >
          <MyAccountProfileLinksLinkItem
            :is-open="isOpen"
            :icon="link.icon"
            :title="link.title"
            :home-profile-is-active="homeProfileIsActive"
            @click="closeSidebar"
          />
        </MevcutLink>

        <MevcutLink to="/about-us">
          <MyAccountProfileLinksLinkItem
            :is-open="isOpen"
            icon="mdi mdi-information-outline"
            :title="t('about_mevcut')"
            :home-profile-is-active="homeProfileIsActive"
          />
        </MevcutLink>
        <MyAccountProfileLinksLinkItem
          v-if="isAuthLoggedInLocal"
          :is-open="isOpen"
          icon="mdi mdi-logout"
          :title="t('logout')"
          :home-profile-is-active="homeProfileIsActive"
          @click="logout"
        />
      </template>
      <template #separator>
        <div class="flex-shrink-0 w-1 sm:w-5"></div>
      </template>
      <template #content>
        <NuxtPage />
      </template>
    </RikazExpandableSidebar>
  </div>
</template>
<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { usePlacesStore } from '~/store/places'
import { useNotificationStore } from '~~/store/notifications'
const localePath = useLocalePath()
const routerPath = computed(() => {
  return useRoute().path
})
const homeProfileIsActive = computed(() => {
  return routerPath.value === localePath('/my-account')
})
watch(
  () => routerPath.value,
  () => {
    if (homeProfileIsActive.value) {
      isOpen.value = homeProfileIsActive.value
    }
  }
)
const isOpen = ref(homeProfileIsActive.value)
const notifications = useNotificationStore()
const { isAuthLoggedIn } = useMenuModel()
const { signOut } = useAuth()
const isAuthLoggedInLocal = computed(() => {
  return isAuthLoggedIn()
})
const { t } = useI18n()
const screenWidth = 768
const links = computed(() => {
  const linksLocal = [
    {
      title: t('notifications'),
      icon: 'mdi mdi-bell-outline',
      route: '/my-account/notifications'
    },
    {
      title: t('cart'),
      icon: 'mdi mdi-cart',
      route: '/cart'
    },
    {
      title: t('wishlist'),
      icon: 'mdi mdi-heart',
      route: '/my-account/wishlist'
    },
    {
      title: t('orders'),
      icon: 'mdi mdi-card-account-phone-outline',
      route: '/my-account/orders'
    },
    {
      title: t('money_movements'),
      icon: 'mdi mdi-bell-outline',
      route: '/my-account/money-movements'
    },
    {
      title: t('saved_places'),
      icon: 'mdi mdi-map-marker',
      route: '/my-account/places'
    }
  ]
  if (!isAuthLoggedInLocal.value) {
    linksLocal.splice(
      0,
      0,
      ...[
        {
          title: t('login'),
          icon: 'mdi mdi-login',
          route: '/auth/login'
        },
        {
          title: t('register'),
          icon: 'mdi mdi-account-multiple-plus',
          route: '/auth/register'
        }
      ]
    )
  }
  if (isAuthLoggedInLocal.value) {
    linksLocal.splice(0, 0, {
      title: t('edit_profile'),
      icon: 'mdi mdi-account-edit',
      route: '/my-account/edit'
    })
  }
  if (!homeProfileIsActive.value) {
    linksLocal.splice(0, 0, {
      title: t('profile'),
      icon: 'mdi mdi-account-outline',
      route: '/my-account'
    })
  }
  return linksLocal
})

onMounted(() => {
  if (!homeProfileIsActive.value) {
    isOpen.value = window.innerWidth > screenWidth
  }
})
const { clearCache } = usePlacesStore()
async function logout() {
  notifications.setUnRead(0)
  useCartStore().clear()
  if (localStorage.getItem('mevcut.firebaseToken')) {
    try {
      await useBasicFetch(
        getAllRoutes().profileRoutes.deleteInstanceIdByServer,
        {
          method: 'POST',
          body: {
            token: localStorage.getItem('mevcut.firebaseToken')
          }
        }
      )

      localStorage.removeItem('mevcut.firebaseToken')
      clearCache()
    } catch (error) {}
  }

  await signOut({
    callbackUrl: '/',
    redirect: false
  })

  window.location.pathname = '/'
}

function closeSidebar() {
  if (isOpen.value) {
    isOpen.value = window.innerWidth > screenWidth
  }
}
</script>

<style>
.router-link-exact-active {
  @apply text-nav_color !important;
}
</style>
