<template>
  <RikazExpandableSidebar
    v-model="isOpen"
    class="py-2"
    sidebar="bg-bg_alt_color rounded p-2 shadow"
    open-width="w-48"
    closed-width="w-9"
  >
    <template #sidebar>
      <button
        class="w-5 h-5 mb-2 ltr:mr-auto rtl:ml-auto"
        @click="isOpen = !isOpen"
      >
        <v-icon icon="mdi mdi-menu" :size="20" />
      </button>
      <MevcutLink
        v-for="(link, index) in links"
        :key="`profile-link-${index}`"
        :with-branch-id-query="false"
        :to="link.route"
        :aria-label="link.title"
      >
        <MyAccountProfileLinksLinkItem
          :is-open="isOpen"
          :icon="link.icon"
          :title="link.title"
          :home-profile-is-active="false"
          @click="closeSidebar"
        />
      </MevcutLink>
      <MyAccountProfileLinksLinkItem
        :is-open="isOpen"
        icon="mdi mdi-logout"
        :title="t('logout')"
        :home-profile-is-active="false"
        @click="logout"
      />
    </template>
    <template #separator>
      <div class="flex-shrink-0 w-1 sm:w-5"></div>
    </template>
    <template #content>
      <NuxtPage />
    </template>
  </RikazExpandableSidebar>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { usePlacesStore } from '~/store/places'
import { useNotificationStore } from '~~/store/notifications'

const isOpen = ref(false)
const notifications = useNotificationStore()
const { signOut } = useAuth()
const { t } = useI18n()
const screenWidth = 768

const links = [
  {
    title: t('profile'),
    icon: 'mdi mdi-account-outline',
    route: '/my-account'
  },
  {
    title: t('orders'),
    icon: 'mdi mdi-card-account-phone-outline',
    route: '/my-account/orders'
  },
  {
    title: t('money_movements'),
    icon: 'mdi mdi-bell-outline',
    route: '/my-account/money-movements'
  },
  {
    title: t('notifications'),
    icon: 'mdi mdi-bell-outline',
    route: '/my-account/notifications'
  },
  {
    title: t('saved_places'),
    icon: 'mdi mdi-map-marker',
    route: '/my-account/places'
  },
  {
    title: t('wishlist'),
    icon: 'mdi mdi-heart',
    route: '/my-account/wishlist'
  }
]

onMounted(() => {
  isOpen.value = window.innerWidth > screenWidth
})
const { clearCache } = usePlacesStore()
async function logout() {
  notifications.setUnRead(0)

  if (localStorage.getItem('mevcut.firebaseToken')) {
    try {
      await useBasicFetch(getAllRoutes().profileRoutes.deleteInstanceIdByServer, {
        method: 'POST',
        body: {
          token: localStorage.getItem('mevcut.firebaseToken')
        }
      })

      localStorage.removeItem('mevcut.firebaseToken')
      clearCache()
    } catch (error) {}
  }

  await signOut({
    callbackUrl: '/',
    redirect: false
  })

  window.location.pathname = '/'
}

function closeSidebar() {
  if (isOpen.value) {
    isOpen.value = window.innerWidth > screenWidth
  }
}
</script>

<style scoped>
.router-link-exact-active {
  @apply text-nav_color !important;
}
</style>
